import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Button, Card, Col, Pagination } from "react-bootstrap";
import { getNotification } from "../../api/NotificationService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import moment from "moment";

export default function Notification() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchListData = async () => {
    setLoader(true);
    try {
      const response = await getNotification();
      console.log(response, "get users response");
      setData(response?.data?.data?.notifications);
      setLoader(false);
    } catch (error) {
      console.log(error, "get users error ");
    }
  };
  useEffect(() => {
    if (data.length === 0) {
      fetchListData();
    }
  }, []);
  return (
    <div>
      <PageTitle activeMenu="Notification" motherMenu="Notification" />

      <Col>
        {data?.map((item, i) => (
          <Card key={i}>
            <Card.Body>
              <div>
                <h3 className="text-black">{item?.title}!</h3>
                <p className="fs-14">
                  Description: Resisterd on{" "}
                  {moment(item?.createdAt).format("DD/MM/YYYY")}.
                </p>
                <div className="d-flex justify-content-between align-items-center fs-16 mt-3">
                  <div
                    className="d-flex align-items-center pointer"
                    // onClick={() => (
                    //   dispatch(setUserIdAction(item?._id)),
                    //   history.push("/user-profile")
                    // )}
                  >
                    <img
                      src={item?.profilePic ? item.profilePic : dummy}
                      alt=""
                      width={80}
                      height={80}
                
                    />
                    <div className="pl-5">
                      <p className="">
                        {item?.gender
                          ? item?.name + ", " + item?.gender
                          : item?.name}
                      </p>

                      <p className="">{item?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="">
                    <Button
                      type="button"
                      className="btn-secondary py-2 px-4 mr-4"
                      //   onClick={() => showModal(item._id)}
                    >
                      Reject
                    </Button>
                    <Button
                      className="bg-color py-2 px-4 "
                      type="button"
                      onClick={() => {
                        // onApprove(item._id, true);
                      }}
                    >
                      Approve
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
        {/* {requests?.length === 0 && !loader ? (
          <div className="justify-content-center d-flex my-5 ">
            Sorry, Notification not found!
          </div>
        ) : (
          ""
        )}
        {listLenght > 0 && (
          <div className="card-footer clearfix">
            <div className="d-flex justify-content-between align-items-center">
              <div className="dataTables_info">
                Showing {currentPage * limit + 1} to{" "}
                {listLenght > (currentPage + 1) * limit
                  ? (currentPage + 1) * limit
                  : listLenght}{" "}
                of {listLenght} entries
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </div>
        )} */}
      </Col>
    </div>
  );
}
