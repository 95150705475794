import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { postContent } from "../../../api/ContentService/ContentService";
import Spinner from "../common/Spinner";
import addPhoto from "../../../services/AuthService";

require("dotenv").config();
export default function AddContent({
  show,
  table,
  category,
  subCategory,
  onHide,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [urlBook, setUrlBook] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  let errorsObj = {
    title: "",
    description: "",
    url: "",
    urlBook: "",
    thumbnail: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECERET_ACCESS_KEY,
  };
  // let thumbnailUrl = {};
  let albumName = "allin";

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUrlBook(inputValue);

    // Regular expression pattern for a URL (you can modify this pattern)
    const urlPattern =
      /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (inputValue === "") {
      setValidationMessage("This Field is Required");
    } else if (urlPattern.test(inputValue)) {
      setValidationMessage("");
    } else {
      setValidationMessage("Invalid URL");
    }
  };
  // console.log(thumbnail, "thumbnail");
  const handleSubmit = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (title.trim() === "") {
      errorObj.title = "This Field is Required";
      error = true;
    }
    if (description.trim() === "") {
      errorObj.description = "This Field is Required";
      error = true;
    }
    if (subCategory === "BOOKS") {
      if (thumbnail === "") {
        errorObj.thumbnail = "This Field is Required";
        error = true;
      }
    } else if (subCategory === "LIBRARY") {
      if (url === "") {
        errorObj.url = "This Field is Required";
        error = true;
      }
    } else {
      // if (thumbnail === "") {
      //   errorObj.thumbnail = "This Field is Required";
      //   error = true;
      // }
      if (url === "") {
        errorObj.url = "This Field is Required";
        error = true;
      }
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);

    try {
      let responseUrl = {};
      if (subCategory !== "BOOKS") {
        responseUrl = await addPhoto(url, albumName);
        console.log(responseUrl.Location, "Image Upload");
      }

      const thumbnailUrl = await addPhoto(thumbnail, albumName);
      let contentUrl = subCategory === "BOOKS" ? urlBook : responseUrl.Location;
      // console.log(thumbnailUrl, "thumbnailUrl");

      console.log(thumbnailUrl?.Location, "helo thumbnail image");
      if (contentUrl && thumbnailUrl.Location) {
        const response = await postContent(
          contentUrl,
          title,
          description,
          category,
          subCategory,
          thumbnailUrl.Location
        );
        console.log(response, "get users response");
        table();
        onHide();
        notifyTopRight(response.data.message);
      } else {
        // Handle the case where either image or certificate upload failed
        console.log("Image or certificate upload failed");
        setLoader(false);
      }
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "get users error ");
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="" role="document">
        <form>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Content</h4>
            {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Title</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <span className="validation-text"></span>
                        {errors.title && (
                          <div className="text-danger fs-12">
                            {errors.title}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Description
                      </label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <span className="validation-text"></span>
                        {errors.description && (
                          <div className="text-danger fs-12">
                            {errors.description}
                          </div>
                        )}
                      </div>
                    </div>{" "}
                    {subCategory === "LIBRARY" ? (
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">PDF</label>
                        <div className="contact-name">
                          <input
                            type="file"
                            accept=".pdf"
                            className="form-control"
                            placeholder="Enter comment"
                            onChange={(e) => setUrl(e.target.files[0])}
                          />
                          {errors.url && (
                            <div className="text-danger fs-12">
                              {errors.url}
                            </div>
                          )}
                          <span className="validation-text"></span>
                        </div>
                      </div>
                    ) : subCategory === "BOOKS" ? (
                      <>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Link</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter comment"
                              // onChange={(e) => setUrlBook(e.target.value)}
                              onChange={handleInputChange}
                            />
                            <span className="validation-text text-danger fs-12">
                              {validationMessage}
                            </span>
                            {/* {errors.urlBook && (
                            <div className="text-danger fs-12">
                              {errors.urlBook}
                            </div>
                          )} */}
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Thumbnail
                          </label>
                          <div className="contact-name">
                            <input
                              type="file"
                              accept="image/*"
                              className="form-control"
                              placeholder="Enter comment"
                              onChange={(e) => setThumbnail(e.target.files[0])}
                            />
                            <span className="validation-text"></span>
                            {errors.thumbnail && (
                              <div className="text-danger fs-12">
                                {errors.thumbnail}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : subCategory === "PODCAST" ? (
                      <>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Video</label>
                          <div className="contact-name">
                            <input
                              type="file"
                              accept="video/*"
                              className="form-control"
                              placeholder="Enter comment"
                              onChange={(e) => setUrl(e.target.files[0])}
                              // onChange={handleFileInputChange}
                            />
                            <span className="validation-text"></span>
                            {errors.url && (
                              <div className="text-danger fs-12">
                                {errors.url}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Thumbnail
                          </label>
                          <div className="contact-name">
                            <input
                              type="file"
                              accept="image/*"
                              className="form-control"
                              placeholder="Enter comment"
                              onChange={(e) => setThumbnail(e.target.files[0])}
                            />
                            <span className="validation-text"></span>
                            {errors.thumbnail && (
                              <div className="text-danger fs-12">
                                {errors.thumbnail}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Video</label>
                          <div className="contact-name">
                            <input
                              type="file"
                              accept="video/*"
                              className="form-control"
                              placeholder="Enter comment"
                              onChange={(e) => setUrl(e.target.files[0])}
                              // onChange={handleFileInputChange}
                            />
                            <span className="validation-text"></span>
                            {errors.url && (
                              <div className="text-danger fs-12">
                                {errors.url}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Thumbnail
                          </label>
                          <div className="contact-name">
                            <input
                              type="file"
                              accept="image/*"
                              className="form-control"
                              placeholder="Enter comment"
                              onChange={(e) => setThumbnail(e.target.files[0])}
                            />
                            <span className="validation-text"></span>
                            {errors.thumbnail && (
                              <div className="text-danger fs-12">
                                {errors.thumbnail}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn theme-btn"
                  onClick={handleSubmit}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary"
                >
                  Discard
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </Modal>
  );
}
