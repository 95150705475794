import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import ContentCard from "../components/ContentCard";
import icon from "../../images/ALL IN LOGO MASTER 4.svg";

const Contentmanagement = () => {



  return (
    <div>
      <PageTitle activeMenu="Content" motherMenu="Content Management" />

      <div>
       
        <ContentCard
          title="HUB"
          text="Home to all your All In resources."
          path="/hub-management"
          image={icon}
        />
        <ContentCard
          title="SHOP"
          text="Our latest products."
          image={icon}
          path="/shop-management"
        />
        <ContentCard
          title="EVENTS"
          text="Stay upto date with latest events."
          path="/event-management"
          image={icon}
        />
        {/* <ContentCard title="SUPPORT" text="Help is at hand, and you can contact us." image={icon}/> */}
      </div>
    </div>
  );
};

export default Contentmanagement;
