import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// Pages

import Login from "./pages/Login";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserManagement from "./pages/UserManagement";
import UserDetails from "./pages/UserDetails";
import Notification from "./pages/Notification";
import HubManagement from "./pages/HubManagement";
import VideoContentManagement from "./pages/VideoContentManagement";
import ShopManagement from "./pages/ShopManagement";
import EventMangement from "./pages/EventMangement";
import Contentmanagement from "./pages/Contentmanagement";
import Message from "./pages/MessageManagement/Message";
import NoticeBoardMessage from "./pages/MessageManagement/NoticeBoardMessage";
import SubCategoryMessage from "./pages/MessageManagement/SubCategoryMessage";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    { url: "page-login", component: Login },

    { url: "user-management", component: UserManagement },
    { url: "user-details", component: UserDetails },
    { url: "notification", component: Notification },
    //// content-management
    { url: "content-management", component: Contentmanagement },
    { url: "hub-management", component: HubManagement },
    { url: "video-content-management", component: VideoContentManagement },
    { url: "shop-management", component: ShopManagement },
    { url: "event-management", component: EventMangement },
    /////message-management
    { url: "message-management", component: Message },
    { url: "notice-management", component: NoticeBoardMessage },
    { url: "about-management", component: SubCategoryMessage },


  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
