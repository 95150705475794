import React from "react";
import ContentCard from "../components/ContentCard";
import PageTitle from "../layouts/PageTitle";
import icon from "../../images/ALL IN LOGO MASTER 4.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import arrow from "../../images/path9417.svg";
export default function HubManagement(props) {
  const category = props.location.state;
  const history = useHistory();
  return (
    <div>
      <div className="d-flex align-items-start ">
        <img
          src={arrow}
          className="p-2 mr-2 pointer"
          onClick={() => history.push("/content-management")}
        />
        <PageTitle activeMenu="HUB" motherMenu="Content Management" />
      </div>
      <div>
       
        <ContentCard
          title="ALL IN TV"
          text="Weekly training for professional growth"
          path="/video-content-management"
          image={icon}
        />
        <ContentCard
          title="COLLECTION"
          text="10 Industry basics to go Pro"
          path="/video-content-management"
          image={icon}
        />
        <ContentCard
          title="LIBRARY"
          text="Business building tools"
          path="/video-content-management"
          image={icon}
        />
        <ContentCard
          title="BOOKS"
          text="Expand your knowledge"
          path="/video-content-management"
          image={icon}
        />
        <ContentCard
          title="PODCAST"
          text="Hear from those gone before you"
          path="/video-content-management"
          image={icon}
        />
      </div>
    </div>
  );
}
