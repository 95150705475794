import React from "react";
import Lottie from "lottie-react";
import animationData from "../../../images/spinner.json";
export default function Spinner() {
  return (
    <div className="custom-spinner">
      <Lottie
        animationData={animationData}
        style={{ height: "200px", marginTop: "45px", padding: "30px" }}
      />
    </div>
  );
}
