import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Badge, Card, Col, Dropdown, Row, Table } from "react-bootstrap";
import { blockUserById, getUsers } from "../../api/api";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { userId } from "../../utils";
import moment from "moment";
import Spinner from "../components/common/Spinner";
import Pagination from "../components/common/Pagination";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import countryList from "react-select-country-list";
import Select from "react-select";
import { notifyError, notifyTopRight } from "../components/common/Toaster";

const UserManagement = () => {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const history = useHistory();
  const [usersData, setUsersData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [listLength, setListlenght] = useState("");
  const limit = 10;
  console.log(usersData, "users data");

  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  /////  select country  /////
  const changeHandler = (value) => {
    console.log(value.label, "country ");
    setCountry(value.label);
  };

  ////////  get users list   ///////////
  const getUsersFun = async () => {
    setLoader(true);
    try {
      const response = await getUsers(currentPage, search, limit, country);
      console.log(response.data.data, "get users response");
      setUsersData(response.data.data.users.Users);
      const total = response.data.data.users.countUser;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.users.countUser);
      setLoader(false);
    } catch (error) {
      console.log(error, "get users error ");
    }
  };

  ////////    block/unblock user  ////////////////
  const blockUserByIdFun = async (id) => {
    try {
      const response = await blockUserById(id);
      notifyTopRight(response.data.data.updateUser);

      getUsersFun();
    } catch (error) {
      notifyError("");
      console.log(error, "block user error ");
    }
  };

  useEffect(() => {
    getUsersFun();
  }, [currentPage, country]);

  useEffect(() => {
    setCurrentPage(0);
    if (currentPage === 0) {
      getUsersFun();
    }
  }, [search]);

  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <div className="col-8">
                <div>
                  <div className="input-group border bg-white input-group-sm rounded-border">
                    <input
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0 search-input"
                      placeholder="Search by name"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setCurrentPage(0);
                          if (currentPage === 0) {
                            getUsersFun();
                          }
                        }
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getUsersFun}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <Select
                  placeholder="Country"
                  options={options}
                  // value={country}
                  onChange={changeHandler}
                />
              </div>
            </Card.Header>
            <Card.Body>
              {loader ? (
                <Spinner />
              ) : (
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>Image</strong>
                      </th>
                      <th>
                        <strong>Name</strong>
                      </th>
                      <th>
                        <strong>EMail</strong>
                      </th>{" "}
                      <th>
                        <strong>Phone Number</strong>
                      </th>{" "}
                      <th>
                        <strong>DOB</strong>
                      </th>
                      <th>
                        <strong>Country</strong>
                      </th>
                      <th>
                        <strong>created on</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  {usersData?.map((item, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>
                          <img
                            src={
                              item?.profileImage ? item?.profileImage : dummy
                            }
                            alt="img"
                            width={60}
                            height={60}
                            className="rounded"
                          />
                        </td>
                        <td style={{maxWidth:"200px"}}>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>
                          {item?.phoneCode} {item?.phoneNumber}
                        </td>
                        <td>{moment(item?.dob).format("MM/DD/YYYY")}</td>
                        <td>{item?.country}</td>

                        <td>{moment(item?.createdAt).format("MM/DD/YYYY")}</td>
                        <td>
                          {!item.isBlocked ? (
                            <Badge variant="success light">Active</Badge>
                          ) : (
                            <Badge variant="danger light">Blocked</Badge>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant={!item.isBlocked ? "primary" : "danger"}
                              className="light sharp i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <DropdownItem
                                onClick={() =>
                                  history.push({
                                    pathname: "/user-details",
                                    state: {
                                      page: currentPage,
                                      id: item._id,
                                    },
                                  })
                                }
                              >
                                <Link to={"/user-details"}>View Details</Link>
                              </DropdownItem>
                              {item.isBlocked ? (
                                <Dropdown.Item
                                  onClick={() => blockUserByIdFun(item._id)}
                                >
                                  Unblock
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => blockUserByIdFun(item._id)}
                                >
                                  Block
                                </Dropdown.Item>
                              )}

                              {/* <Dropdown.Item>Delete</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              )}

              {usersData?.length === 0 && !loader && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              {usersData?.length !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {currentPage * limit + 1} to{" "}
                      {listLength > (currentPage + 1) * limit
                        ? (currentPage + 1) * limit
                        : listLength}{" "}
                      of {listLength} entries
                    </div>
                    <Pagination
                      pageCount={pageCount}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserManagement;
