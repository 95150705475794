/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = ["", "dashboard"],
      User = ["user-management"],
      content = ["content-management"],
      message = ["message-management", "notice-management", "about-management"],
      notification = ["notification"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link to="/" className="ai-icon">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${User.includes(path) ? "mm-active" : ""}`}>
              <Link to="user-management" className="ai-icon">
                <i className="flaticon-381-user-9"></i>
                <span className="nav-text">User Management</span>
              </Link>
            </li>
            <li className={`${content.includes(path) ? "mm-active" : ""}`}>
              <Link to="content-management" className="ai-icon">
                <i className="flaticon-381-settings-9"></i>
                <span className="nav-text">Content Management</span>
              </Link>
            </li>
            <li className={`${message.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-notification"></i>
                {/* <img src={articalIcon}></img> */}
                <span className="nav-text">Message Management</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "message-management" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/message-management"
                  >
                    Message
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "notice-management" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/notice-management"
                  >
                    Hub Notice
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "about-management" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/about-management"
                  >
                    Subcategory About
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className={`${notification.includes(path) ? "mm-active" : ""}`}>
              <Link to="notification" className="ai-icon">
                <i className="flaticon-381-notification"></i>
                <span className="nav-text">Notification</span>
              </Link>
            </li> */}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
