import React from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function ContentCard({ title, text, path, image }) {
  const history = useHistory();

  return (
    <div
      className="card-style py-3 px-5 d-flex justify-content-between align-items-center mb-3 pointer"
      onClick={() => history.push(path, title)}
    >
      <div>
        <h3 className="m-0 text-color">{title}</h3>
        <p className="m-0 text-white">{text}</p>
      </div>

      <img src={image} />
    </div>
  );
}
