import { instance } from "./instance";

// Dashboard API

export const getDashboard = async () => {
  const result = await instance.get(`admin/auth/dashboard`);
  return result;
};

////post image/video

export const uploadImageVideo = async (image) => {
  const postData = {
    loginPageImage: image,
  };
  const result = await instance.post(`admin/createdisplay`, postData);
  return result;
};

// user list API

export const getUsers = async (currentPage, search, limit, country) => {
  const result = await instance.get(
    `admin/userManagement/listOfUsers?page=${currentPage}&limit=${limit}&search=${search}&country=${country}`
  );
  return result;
};

// User Details API

export const getUserDetailsById = async (userId) => {
  const result = await instance.get(
    `admin/userManagement/viewdetails?userId=${userId}`
  );
  return result;
};

export const blockUserById = async (userId) => {
  const postData = {
    userId,
  };
  const result = await instance.put(`admin/userManagement/blockUser`, postData);
  return result;
};

export const unBlockUserById = async (userId) => {
  const result = await instance.put(
    `admin/userManagement/unblockUser?userId=${userId}`
  );
  return result;
};
