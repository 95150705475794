import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import AddContent from "../components/modal/AddContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import arrow from "../../images/path9417.svg";
import {
  deleteContent,
  getContentList,
} from "../../api/ContentService/ContentService";
import Spinner from "../components/common/Spinner";
import { notifyError, notifyTopRight } from "../components/common/Toaster";
export default function VideoContentManagement(props) {
  const history = useHistory();
  // console.log(props, "props in last page");
  const pageTitle = props.location.state;
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  ////////  get content list   ///////////
  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getContentList("hub", pageTitle);
      console.log(response.data.data, "get users response");
      setData(response.data.data.Contents);
      setLoader(false);
    } catch (error) {
      console.log(error, "get users error ");
    }
  };

  ////////    function to delete one item  ////////////////
  const handleDelete = async (id) => {
    try {
      const response = await deleteContent(id);
      notifyTopRight(response.data.message);
      handleFetch();
    } catch (error) {
      notifyError("");
      console.log(error, "error ");
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <div>
      {" "}
      <div className="d-flex align-items-start ">
        <img
          src={arrow}
          className="p-2 mr-2 pointer"
          onClick={() => history.push("/hub-management")}
        />
        <PageTitle activeMenu={pageTitle} motherMenu="HUB" />
      </div>
      <div>
        <div className="py-3 px-2 mb-5 d-flex  justify-content-between">
          <h3 className="text-black text-bold">Sort By - Latest</h3>
          <button
            className="btn theme-btn py-2 px-2"
            onClick={() => setShow(true)}
          >
            Add +
          </button>
        </div>
        {loader ? (
          <Spinner />
        ) : (
          data?.map((item) => (
            <div className="card-style py-3 px-5 d-flex justify-content-between align-items-center mb-3">
              <div style={{overflowWrap:"anywhere"}}>
                <h3 className="m-0 text-color">{item?.title}</h3>
                <p className="m-0 text-white">{item?.description}</p>
              </div>
              {item?.subCategory === "LIBRARY" ? (
                <div className="d-flex">
                  <a href={item?.url} target="_blank">
                    <button type="button" className="btn theme-btn py-2">
                      View
                    </button>
                  </a>
                  <div className="ml-1">
                    <button
                      className="btn btn-danger light p-1"
                      onClick={() => handleDelete(item._id)}
                    >
                      <i className="flaticon-381-trash-1"></i>
                    </button>
                  </div>
                </div>
              ) : item?.subCategory === "BOOKS" ? (
                <div className="d-flex">
                  <a href={item?.url} target="_blank">
                    <button type="button" className="btn theme-btn py-2">
                      Link
                    </button>
                  </a>
                  <div className="ml-1">
                    <button
                      className="btn btn-danger light p-1"
                      onClick={() => handleDelete(item._id)}
                    >
                      <i className="flaticon-381-trash-1"></i>
                    </button>
                  </div>
                </div>
              ) : item?.subCategory === "PODCAST" ? (
                // <div className="d-flex">
                //   <div
                //     className="d-flex flex-column justify-content-end custom-audio"
                //     style={{ backgroundImage: `url(${item?.thumbnail})` }}
                //   >
                //     <audio controls>
                //       <source src={item?.url} type="video/mp4"></source>
                //     </audio>
                //   </div>
                //   <div className="ml-1">
                //     <button
                //       className="btn btn-danger light p-1"
                //       onClick={() => handleDelete(item._id)}
                //     >
                //       <i className="flaticon-381-trash-1"></i>
                //     </button>
                //   </div>
                // </div>
                <div className="custom-video d-flex justify-content-end">
                <video
                  width="300"
                  height="200"
                  controls
                  poster={item?.thumbnail ? item?.thumbnail : ""}
                >
                  <source src={item?.url} type="video/mp4"></source>
                </video>
                <div className="ml-1">
                  <button
                    className="btn btn-danger light p-1"
                    onClick={() => handleDelete(item._id)}
                  >
                    <i className="flaticon-381-trash-1"></i>
                  </button>
                </div>
              </div>
              ) : (
                <div className="custom-video d-flex justify-content-end">
                  <video
                    width="300"
                    height="200"
                    controls
                    poster={item?.thumbnail ? item?.thumbnail : ""}
                  >
                    <source src={item?.url} type="video/mp4"></source>
                  </video>
                  <div className="ml-1">
                    <button
                      className="btn btn-danger light p-1"
                      onClick={() => handleDelete(item._id)}
                    >
                      <i className="flaticon-381-trash-1"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
        {data?.length === 0 && !loader && (
          <div className="justify-content-center d-flex my-5 ">
            Sorry, Data Not Found!
          </div>
        )}
      </div>
      {show && (
        <AddContent
          show={show}
          table={handleFetch}
          category={"hub"}
          subCategory={pageTitle}
          onHide={() => setShow(false)}
        />
      )}
    </div>
  );
}
