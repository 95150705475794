// https://api.allinapp.co.uk/

import axios from "axios";

export const instance = axios.create({
  baseURL: `https://api.allinapp.co.uk/`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(async (request) => {
  let token = localStorage.getItem("authToken");
  request.headers.Authorization = `Bearer ${token}`;
  console.log("intercepters");
  return request;
});

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    console.log(error.response.status, "instance error ");
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authToken");

      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
