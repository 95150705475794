import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { getUserDetailsById } from "../../api/api";
import Spinner from "../components/common/Spinner";
import moment from "moment";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
const UserDetails = (props) => {
  const id = props.location.state.id;
  const [loader, setLoader] = useState(false);
  const [userDetailsData, setUserDetailsData] = useState({});
  console.log(userDetailsData, "user details data");

  const getUserDetailsByIdFun = async (id) => {
    setLoader(true);
    try {
      const response = await getUserDetailsById(id);
      console.log(response, "get users details response");
      setUserDetailsData({ ...response.data.data.user });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error.response, "get user details error ");
    }
  };

  useEffect(() => {
    getUserDetailsByIdFun(id);
  }, []);

  return (
    <div>
      <PageTitle activeMenu="User Details" motherMenu="User Management" />

      {loader ? (
        <Spinner />
      ) : (
        <div className="card" style={{ width: "70%" }}>
          <div className="card-header">
            <div className="d-flex" style={{ gap: "1rem", flexGrow: "1" }}>
              <div>
                <img
                  src={
                    userDetailsData?.profileImage
                      ? userDetailsData?.profileImage
                      : dummy
                  }
                  width="120"
                  height="120"
                  className="text-center rounded"
                />
              </div>
              <div className="text-left" style={{ flex: "1 1 0%" }}>
                <h3 className="text-color ">{userDetailsData?.name}</h3>

                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Date Of Birth</h5>
                  <p className="mb-0">
                    {moment(userDetailsData?.dob).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Email</h5>
                  <p className="mb-0">{userDetailsData?.email}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Phone Number</h5>
                  <p className="mb-0">
                    {userDetailsData?.phoneCode} {userDetailsData?.phoneNumber}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="text-black">
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Created At</h5>
                <p className="m-0">
                  {moment(userDetailsData?.createdAt).format("MM/DD/YYYY")}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Country</h5>
                <p className="m-0">{userDetailsData?.country}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
