import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import AddShopDetails from "../components/modal/AddShopDetails";
import { Card, Dropdown, Table } from "react-bootstrap";
import Spinner from "../components/common/Spinner";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import arrow from "../../images/path9417.svg";
import { useHistory } from "react-router-dom";
import {
  deleteContent,
  getContentList,
} from "../../api/ContentService/ContentService";
import Pagination from "../components/common/Pagination";
import { notifyError, notifyTopRight } from "../components/common/Toaster";
import EditContent from "../components/modal/EditContent";

export default function EventMangement(props) {
  const pageTitle = props.location.state;
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [oneData, setOneData] = useState({});
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState("");
  const limit = 10;
  ////////  get content list   ///////////
  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getContentList("events");
      console.log(response.data.data, "get response");
      setData(response.data.data.Contents);
      const total = response.data.data.countContents;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.countContents);
    } catch (error) {
      console.log(error, "get error ");
    } finally {
      setLoader(false);
    }
  };

  ////////    function to delete one item  ////////////////
  const handleDelete = async (id) => {
    try {
      const response = await deleteContent(id);
      notifyTopRight(response.data.message);
      handleFetch();
    } catch (error) {
      notifyError("");
      console.log(error, "block user error ");
    }
  };
  ////////    function to edit one item  ////////////////
  const onEdit = async (data) => {
    setOneData(data);
    setShowEditModal(true);
  };
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex align-items-start ">
          <img
            src={arrow}
            className="p-2 mr-2 pointer"
            onClick={() => history.push("/content-management")}
          />
          <PageTitle activeMenu="EVENTS" motherMenu="Content Management" />
        </div>
        <button
          className="btn theme-btn py-2 px-2"
          onClick={() => setShow(true)}
        >
          Add +
        </button>
      </div>
      <Card>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th className="width80">
                    <strong>Image</strong>
                  </th>
                  <th>
                    <strong>Bio</strong>
                  </th>
                  <th>
                    <strong>link</strong>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              {data?.map((item, index) => (
                <tbody key={index}>
                  <tr>
                    <td>
                      <img
                        src={item?.url ? item?.url : dummy}
                        alt="img"
                        width={80}
                        height={80}
                        className="rounded"
                      />
                    </td>
                    <td>{item?.title}</td>
                    <td>{item?.description}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant={"primary"}
                          className="light sharp i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => onEdit(item)}>
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item onClick={() => handleDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          )}

          {data?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}
          {data?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {show && (
        <AddShopDetails
          show={show}
          onHide={() => setShow(false)}
          name={"Bio"}
          category={"events"}
          table={handleFetch}
        />
      )}
      {showEditModal && (
        <EditContent
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          name={"Bio"}
          category={"events"}
          table={handleFetch}
          data={oneData}
        />
      )}
    </div>
  );
}
