import React, { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { notifyError, notifyTopRight } from "../../components/common/Toaster";
import {
  deleteMessage,
  getMessage,
  postMessage,
} from "../../../api/Message/MessageService";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import Spinner from "../../components/common/Spinner";
import Pagination from "../../components/common/Pagination";
import moment from "moment";
import EditMessage from "../../components/modal/EditMessage";
export default function Message() {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  let errorsObj = { message: "", title: "", time: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [time, setTime] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [messageId, setMessageId] = useState("");

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    // Concatenate the date and time strings and parse them using moment
    const dateTimeString = `${startDate} ${selectedTime}`;

    // Parse the combined date and time using moment
    const momentObject = moment(dateTimeString, "YYYY-MM-DD HH:mm");

    // Format the time in ISO string format
    const isoString = momentObject.toISOString();

    setTime(isoString);
    console.log(isoString, "ISO string time");
  };

  ////////  post message   ///////////

  const handleSubmit = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (message === "") {
      errorObj.message = "Message is Required";
      error = true;
    }
    if (startDate === "") {
      errorObj.startDate = "Start date is Required";
      error = true;
    }
    // if (endDate === "") {
    //   errorObj.endDate = "End date is Required";
    //   error = true;
    // }
    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (time === "") {
      errorObj.time = "Time is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader1(true);
    try {
      const response = await postMessage(
        title,
        message,
        "message",
        startDate,
        // endDate,
        time
      );
      console.log(response, "get users response");
      setTime("");
      setMessage("");
      setStartDate("");
      // setEndDate("");
      setTitle("");
      handleFetch();
      notifyTopRight(response.data.message);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "get users error ");
    } finally {
      setLoader1(false);
    }
  };

  ////////  get message   ///////////
  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getMessage("message");
      console.log(response.data.data, "get response");
      setMessageList(response.data.data);
    } catch (error) {
      console.log(error, "get users error ");
    } finally {
      setLoader(false);
    }
  };
  ////////    delete message  ////////////////
  const handleEdit = async (data) => {
    setMessageId(data);
    setShowModal(true);
  };
  ////////    delete message  ////////////////
  const handleDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteMessage(id);
      notifyTopRight(response.data?.data?.data);
      handleFetch();
    } catch (error) {
      notifyError(error?.response?.data);
      console.log(error, "block user error ");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <PageTitle activeMenu="Message" motherMenu="Message Management" />
      {loader1 ? (
        <Spinner />
      ) : (
        <div className="form-group mb-5 card-style p-5 ">
          <label className="text-color font-w500">Title</label>
          <div className="contact-name ">
            <input
              type="text"
              className="form-control"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {errors.title && (
              <div className="text-danger fs-12">{errors.title}</div>
            )}
          </div>
          <label className="text-color font-w500">Message</label>
          <div className="contact-name ">
            <textarea
              type="text"
              className="form-control"
              rows={5}
              placeholder="message.."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              maxLength={250}
            />
            {errors.message && (
              <div className="text-danger fs-12">{errors.message}</div>
            )}
          </div>

          <label className="text-color font-w500 mt-3">Start Date</label>
          <div className="contact-name ">
            <input
              type="date"
              id="start"
              name="trip-start"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              min={currentDate}
            />
            {errors.startDate && (
              <div className="text-danger fs-12">{errors.startDate}</div>
            )}
          </div>
          {/* <label className="text-color font-w500 mt-3">End Date</label>
          <div className="contact-name ">
            <input
              type="date"
              placeholder="dd-mm-yyyy"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              // min={startDate || undefined} // Set min to undefined if no start date
            />
            {errors.endDate && (
            <div className="text-danger fs-12">{errors.endDate}</div>
          )}
          </div> */}
          <label className="text-color font-w500 mt-3">Time</label>
          <div className="contact-name ">
            <input
              type="time"
              className="form-control"
              // value={time}
              onChange={handleTimeChange}
            />
            {errors.time && (
              <div className="text-danger fs-12">{errors.time}</div>
            )}
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn mt-3 theme-btn py-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <Card>
        <Card.Header>
          <h3 className="text-black">Listing</h3>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>Title</strong>
                  </th>
                  <th>
                    <strong>Message</strong>
                  </th>
                  <th>
                    <strong>Start Date</strong>
                  </th>
                  {/* <th>
                    <strong>End date</strong>
                  </th> */}
                  <th>
                    <strong>Time</strong>
                  </th>
                </tr>
              </thead>
              {messageList &&
                messageList?.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td>{item?.title}</td>
                      <td style={{maxWidth:"200px"}}>{item?.message}</td>
                      <td>{item?.startDate?moment(item?.startDate).format("MM/DD/YYYY"):""}</td>
                      {/* <td>{moment(item?.endDate).format("MM/DD/YYYY")}</td> */}
                      <td>{item?.time?moment(item?.time).format("h:mm A"):""}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant={"primary"}
                            className="light sharp i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleEdit(item)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleDelete(item?._id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </Table>
          )}

          {messageList?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}
          {/* {messageList?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )} */}
        </Card.Body>
      </Card>
      {showModal && (
        <EditMessage show={showModal} data={messageId} table={handleFetch} onHide={() => setShowModal(false)} />
      )}
    </div>
  );
}
