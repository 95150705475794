import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { instance } from "../api/instance";
import AWS from "aws-sdk";
export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    // returnSecureToken: true,
  };

  return instance.post(`admin/auth/login`, postData);

  // return axios.post(
  //     `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
  //     postData,
  // );
}
export function changePassword(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return instance.put("admin/auth/changePassword", postData);
}
export function postSupportForm(name, email, description) {
  const postData = {
    name, email, description
  };

  return instance.post("user/supportForm", postData);
}
export function formatError(errorResponse) {
  switch (errorResponse) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case errorResponse:
      //return 'Email not found';
      swal("Oops", `${errorResponse}`, "error", { button: "Try Again!" });
      break;
    case "Password is Incorrect":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  localStorage.setItem("authToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("authToken");
  //   let tokenDetails = "";
  // if (!tokenDetailsString) {
  //   history.push("/");
  //   dispatch(logout(history));
  //   return;
  // }

  //   tokenDetails = tokenDetailsString;
  //   let expireDate = new Date(tokenDetails.expireDate);
  //   let todaysDate = new Date();

  //   if (todaysDate > expireDate) {
  //     dispatch(logout(history));
  //     return;
  //   }
  // dispatch(loginConfirmedAction(tokenDetailsString));

  //   const timer = expireDate.getTime() - todaysDate.getTime();
  //   runLogoutTimer(dispatch, timer, history);
}

export const logoutApi = async () => {
  const result = await instance.put(`admin/auth/logout`);
  return result;
};

const NEXT_PUBLIC_COGNITO_POOL_ID = process.env.REACT_APP_BUCKET_POOL_ID;
const NEXT_PUBLIC_COGNITO_POOL_REGION = process.env.REACT_APP_BUCKET_REGION;
const NEXT_PUBLIC_S3_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

AWS.config.update({
  region: NEXT_PUBLIC_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: NEXT_PUBLIC_COGNITO_POOL_ID,
  }),
});

export default async function addPhoto(image, albumName) {
  console.log(image, albumName, "file in test files");
  var file = image;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: NEXT_PUBLIC_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  console.log(promise.imageName, "promise 1111111111111111");
  return promise;
}
