import { instance } from "../instance";
import { MESSAGE } from "./MessageEndpoints";

export const postMessage = async (
  title,
  message,
  type,
  startDate,
  // endDate,
  time,
  subCategory
) => {
  const postData = {
    title,
    message,
    type,
    startDate,
    // endDate,
    time,
    subCategory,
  };
  const result = await instance.post(MESSAGE, postData);
  return result;
};
export const getMessage = async (type, subCategory) => {
  let url = MESSAGE + `?type=${type}`;

  if (subCategory) {
    url += `&subCategory=${subCategory}`;
  }
  const result = await instance.get(url);
  return result;
};

export const deleteMessage = async (id) => {
  const result = await instance.delete(MESSAGE + `?messageId=${id}`);
  return result;
};

export const editMessage = async (
  id,
  title,
  message,
  startDate,
  // endDate,
  time
) => {
  const postData = {
    messageId: id,
    title,
    message,
    startDate,
    // endDate,
    time,
  };
  const result = await instance.put(MESSAGE, postData);
  return result;
};
