import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { editMessage } from "../../../api/Message/MessageService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import moment from "moment";
import Spinner from "../common/Spinner";

export default function EditMessage({ show, data, table, onHide }) {
  const [message, setMessage] = useState("");
  let errorsObj = {
    message: "",
    subCategory: "",
    startDate: "",
    title: "",
    time: "",
  };
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [selectSubCtaegory, setSelectSubCtaegory] = useState("");
  const [time, setTime] = useState("");

  //// funtion to add am/pm in date value
  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    // Concatenate the date and time strings and parse them using moment
    const dateTimeString = `${startDate} ${selectedTime}`;

    // Parse the combined date and time using moment
    const momentObject = moment(dateTimeString, "YYYY-MM-DD HH:mm");

    // Format the time in ISO string format
    const isoString = momentObject.toISOString();

    setTime(isoString);
    console.log(isoString, "ISO string time");
  };

  ////////  post message   ///////////
  const handleSubmit = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (message === "") {
      errorObj.message = "Message is Required";
      error = true;
    }
    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader1(true);
    try {
      const response = await editMessage(
        data?._id,
        title,
        message,
        startDate,
        // endDate,
        time
      );
      console.log(response, " response");
      setMessage("");
      setTitle("");
      table();
      onHide();
      notifyTopRight(response.data.message);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "get users error ");
    } finally {
      setLoader1(false);
    }
  };
  useEffect(() => {
    setMessage(data?.message);
    setTitle(data?.title);
    setStartDate(moment(data?.startDate).format("YYYY-MM-DD"));
    // setEndDate(moment(data?.endDate).format("YYYY-MM-DD"));
  }, []);
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <form>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Edit Message</h4>
            {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Title</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {errors.title && (
                          <div className="text-danger fs-12">
                            {errors.title}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Message</label>
                      <div className="contact-name">
                        <textarea
                          type="text"
                          className="form-control"
                          rows={5}
                          placeholder="message.."
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        {errors.message && (
                          <div className="text-danger fs-12">
                            {errors.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Start Date</label>
                      <div className="contact-name">
                        <input
                          type="date"
                          id="start"
                          name="trip-start"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                        {errors.startDate && (
                          <div className="text-danger fs-12">
                            {errors.startDate}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="form-group mb-3">
                      <label className="text-black font-w500">End Date</label>
                      <div className="contact-name">
                        <input
                          type="date"
                          placeholder="dd-mm-yyyy"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          // min={startDate || undefined} // Set min to undefined if no start date
                        />
                        {errors.endDate && (
                          <div className="text-danger fs-12">
                            {errors.endDate}
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Time</label>
                      <div className="contact-name">
                        <input
                          type="time"
                          className="form-control"
                          //   value={time}
                          onChange={handleTimeChange}
                        />
                        {errors.time && (
                          <div className="text-danger fs-12">{errors.time}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn theme-btn"
                  onClick={handleSubmit}
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary"
                >
                  Discard
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      {/* {loader && <Spinner />} */}
    </Modal>
  );
}
