import { instance } from "../instance";
import { CONTENT } from "./ContentEndpoints";

export const getContentList = async (category, subcategory) => {
  let url = CONTENT + `?category=${category}`;

  if (subcategory) {
    url += `&subCategory=${subcategory}`;
  }
  const result = await instance.get(url);
  return result;
};

export const postContent = async (
  url,
  title,
  description,
  category,
  subCategory,
  thumbnail
) => {
  console.log(thumbnail, "image in api function");
  const postData = {
    url,
    title,
    description,
    category,
    subCategory,
    thumbnail,
  };
  const result = await instance.post(CONTENT, postData);
  return result;
};
export const editContent = async (id, image, title, url, category) => {
  const postData = {
    contentId:id,
    url:image,
    title,
    description:url,
    category,
  };
  const result = await instance.put(CONTENT, postData);
  return result;
};
export const deleteContent = async (id) => {
  const result = await instance.delete(CONTENT + `?contentId=${id}`);
  return result;
};
