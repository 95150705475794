import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import {
  editContent,
  postContent,
} from "../../../api/ContentService/ContentService";
import Spinner from "../common/Spinner";
import addPhoto from "../../../services/AuthService";
export default function EditContent({
  show,
  onHide,
  name,
  category,
  table,
  data,
}) {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  let errorsObj = { title: "", image: "", url: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  let albumName = "allin";
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUrl(inputValue);

    // Regular expression pattern for a URL (you can modify this pattern)
    const urlPattern =
      /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (urlPattern.test(inputValue)) {
      setValidationMessage("");
    } else {
      setValidationMessage("Invalid URL");
    }
  };
  const handleInputChangeImage = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      addPhoto(selectedFile, albumName)
        .then((response) => {
          console.log(response.Location, "image loaction");
          setImage(response.Location);
        })
        .catch((error) => {
          console.log(error, "image upload error");
        });
    }
  };
  const handleSubmit = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (title.trim() === "") {
      errorObj.title = "This Field is Required";
      error = true;
    }
    if (image === "") {
      errorObj.image = "This Field is Required";
      error = true;
    }
    if (url === "") {
      errorObj.url = "This Field is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await editContent(
        data?._id,
        image,
        title,
        url,
        category
      );
      console.log(response, "get users response");
      table();
      onHide();
      notifyTopRight(response.data.message);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "get users error ");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (data) {
      setTitle(data?.title);
      setUrl(data?.description);
      setImage(data?.url);
    }
  }, [data]);
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <form>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Edit Content</h4>
            {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Image</label>
                      <div
                        className="contact-name d-flex"
                        style={{ gap: "1rem" }}
                      >
                        <div>
                          <img src={image} width={60} height={60} />
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          onChange={handleInputChangeImage}
                        />
                        <span className="validation-text"></span>
                        {errors.image && (
                          <div className="text-danger fs-12">
                            {errors.image}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">{name}</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <span className="validation-text"></span>
                        {errors.title && (
                          <div className="text-danger fs-12">
                            {errors.title}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Link</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter url"
                          value={url}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <span className="validation-text text-danger fs-12">
                          {validationMessage}
                        </span>
                        {errors.url && (
                          <div className="text-danger fs-12">{errors.url}</div>
                        )}
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn theme-btn"
                  onClick={handleSubmit}
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary"
                >
                  Discard
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      {/* {loader && <Spinner />} */}
    </Modal>
  );
}
